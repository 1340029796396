<template>
  <Modal @close="$emit('close:modal')">
    <template #headline>
      <div>
        <Headline size="xl" tag="h2" class="mb-4">
          {{ $t('login_page.forgot_password.title') }}
        </Headline>
        <p class="mb-4 text-sm">
          {{ $t('login_page.forgot_password.description') }}
        </p>
      </div>
    </template>

    <form>
      <ValidatedInputGroup v-slot="{ isValid }" :errors="v.email.$errors">
        <FimInput
          id="email"
          v-model:value="model.email"
          autocomplete="username"
          :placeholder="$t('form_fields.email')"
          type="email"
          :is-valid="!isValid"
          required
          :disabled="isSubmitting || success"
          @update:value="v.email.$touch()"
        />
      </ValidatedInputGroup>

      <div class="flex flex-row-reverse justify-between">
        <FimButton
          :disabled="isSubmitting || success"
          :loading="isSubmitting"
          @click="onSubmit()"
        >
          {{ $t('login_page.forgot_password.submit') }}
        </FimButton>
        <FimButton
          type="tertiary"
          :disabled="isSubmitting"
          :loading="isSubmitting"
          @click="$emit('close:modal')"
        >
          {{ $t('login_page.forgot_password.cancel') }}
        </FimButton>
      </div>
    </form>
  </Modal>
</template>

<script setup lang="ts">
import useVuelidate from '@vuelidate/core'

defineEmits(['close:modal'])

const { $validation, $currentShop } = useNuxtApp()
const { forgotPassword, isSubmitting } =
  await useAuthentication('forgot_password')
const { lastLoggedInUser } = await useLastLoggedInUser()

const success = ref(false)
const model = reactive({
  email: '',
})

const onSubmit = async () => {
  const isValid = await v.value.$validate()
  if (isValid) {
    success.value = await forgotPassword({
      email: model.email,
      shop_id: $currentShop.shopId,
      reset_url: $currentShop.auth.resetPasswordUrl,
    })
  }
}

watch(lastLoggedInUser, (user) => {
  model.email = user.email
})

const v = useVuelidate(
  {
    email: {
      required: $validation.rule.required,
      email: $validation.rule.email,
    },
  },
  model,
)
</script>
