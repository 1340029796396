import { FetchError } from 'ofetch'
import { Action } from '~/plugins/toast'

const httpErrorMessages: Record<number, string> = {
  400: '400_bad_request',
  401: '401_unauthorized',
  403: '403_user_deactivated',
  404: '404_not_found',
  406: '406_hash_has_expired',
  409: '409_already_exists',
  500: '500_server_error',
} as const

export const useIDPAuthentication = async () => {
  const instance = useNuxtApp()
  const { $alert } = instance
  const i18n = useI18n()
  const route = useRoute()
  const router = useRouter()
  const { localizedNavigateTo } = useRouteHelpers()
  const log = useLog('useIDPAuthentication')

  const [
    { user, forceRefresh },
    { fetch: refreshWishlist },
    { fetch: refreshBasket },
  ] = await Promise.all([useUser(), useWishlist(), useBasket()])

  return instance.runWithContext(async () => {
    const scope = effectScope()
    const refresh = async () => {
      await Promise.all([forceRefresh(), refreshWishlist(), refreshBasket()])
    }

    /**
     * After a user was authenticated by loggin in, or registering.
     * Refresh user data, basket & wishlist.
     */
    const authenticated = async () => {
      await refresh()

      if (user.value) {
        let redirectTo = routeList.account.path

        if (route.query.redirectUrl) {
          redirectTo = route.query.redirectUrl as string
        }

        return instance.runWithContext(async () => {
          await redirectUser(redirectTo)
          $alert.show(i18n.t('login_page.login.status.success'), Action.confirm)
        })
      }

      $alert.show(i18n.t('login_page.login.status.success'), Action.confirm)
    }

    const handleError = (error: unknown) => {
      if (error instanceof FetchError) {
        const status = error.response?.status
        if (status && Object.hasOwn(httpErrorMessages, status)) {
          const errorMessage = i18n.t(
            `login_page.login.status.error.${httpErrorMessages[status]}`,
          )
          $alert.show(errorMessage, Action.confirm)
        }
      }
      // remove user data (email, password) from the error object, before logging it

      // @ts-expect-error config is not available on {}
      delete error?.config?.data

      log.error('Error while authenticating', {
        error,
      })
    }

    const redirectUser = async (redirectTo: string) => {
      return router.currentRoute.value.fullPath === redirectTo
        ? window.location.reload()
        : await localizedNavigateTo(redirectTo)
    }

    // sate and code query param are reserved for IDP usage
    const { state: _state, code: _code, ...rest } = route.query

    const { data: externalIDPRedirects, handleIDPLoginCallback } = await useIDP(
      {
        queryParams: rest as Record<string, string>,
      },
    )

    scope.run(() => {
      if (!import.meta.server) {
        watch(
          () => route.query,
          async (query) => {
            if (import.meta.server) {
              return
            }
            if (query.code && isString(query.code)) {
              try {
                await handleIDPLoginCallback({ code: query.code })
                await authenticated()
              } catch (e) {
                handleError(e)
              }
            }
          },
          { immediate: true },
        )
      }
    })

    tryOnScopeDispose(() => {
      scope.stop()
    })

    return {
      externalIDPRedirects,
    }
  })
}
