<template>
  <div :class="[isBasketDisplayed && isGreaterOrEquals('lg') ? 'p-12' : 'p-4']">
    <ClientOnly>
      <template v-if="externalIDPRedirects && !isEmpty(externalIDPRedirects)">
        <div
          v-if="route.query.code"
          class="flex size-full items-center justify-center pt-12"
        >
          <FimLoadingSpinner />
        </div>
        <template v-else>
          <h1
            v-if="!isBasketDisplayed"
            class="mx-auto mt-10 max-w-[33.75rem] px-2"
          >
            {{ $t('login_page.login.title') }}
          </h1>
          <VerticalAccordion class="mt-2 px-2" :tabs="tabs">
            <template #0>
              <IDPForm :redirects="externalIDPRedirects" />
            </template>

            <template #1>
              <IDPForm :redirects="externalIDPRedirects">
                {{ $t('login_page.sign_up.title') }}
              </IDPForm>
            </template>

            <template
              v-if="showGuestLogin && isBasketDisplayed"
              #2="{ activateTab }"
            >
              <GuestLoginForm />
              <FimButton
                type="tertiary"
                class="w-full"
                @click.stop="activateTab(1)"
              >
                {{ $t('login_page.guest_login.cancel') }}
              </FimButton>
            </template>
          </VerticalAccordion>
        </template>
      </template>
      <template v-else>
        <div v-if="!isLoggedIn && !isFetching">
          <h1
            v-if="isBasketDisplayed && isGreaterOrEquals('lg')"
            class="mx-auto max-w-[33.75rem] px-2"
          >
            {{ $t('login_page.login.contactinformation') }}
          </h1>
          <h1
            v-if="!isBasketDisplayed"
            class="mx-auto mt-10 max-w-[33.75rem] px-2"
          >
            {{ $t('login_page.login.title') }}
          </h1>

          <WelcomeBackLoginForm
            v-if="lastLoggedInUser.email"
            class="mt-2 px-2"
          />

          <VerticalAccordion v-else class="mt-2 px-2" :tabs="tabs">
            <template #0>
              <LoginForm />
            </template>

            <template #1>
              <RegisterForm />
            </template>

            <template
              v-if="showGuestLogin && isBasketDisplayed"
              #2="{ activateTab }"
            >
              <GuestLoginForm />
              <FimButton
                type="tertiary"
                class="w-full"
                @click.stop="activateTab(1)"
              >
                {{ $t('login_page.guest_login.cancel') }}
              </FimButton>
            </template>
          </VerticalAccordion>

          <ForgotPasswordForm
            v-if="isForgotPasswordShown"
            @close:modal="goToSignInPage"
          />

          <UpdatePasswordByHashForm
            v-if="isUpdatePasswordByHashShown"
            @close:modal="goToSignInPage"
          />
        </div>
      </template>
    </ClientOnly>
  </div>
</template>

<script setup lang="ts">
import FimLoadingSpinner from '../ui/FimLoadingSpinner.vue'

const props = defineProps({
  showGuestLogin: {
    type: Boolean,
    default: false,
  },
  isBasketDisplayed: {
    type: Boolean,
    default: false,
  },
})

const $i18n = useI18n()
const { lastLoggedInUser, isLoggedIn, isFetching } = await useLastLoggedInUser()

const route = useRoute()

const tabs = computed(() => [
  $i18n.t('login_page.login.title'),
  $i18n.t('login_page.sign_up.title'),
  ...(props.showGuestLogin && props.isBasketDisplayed
    ? [$i18n.t('login_page.guest_login.title')]
    : []),
])

const isForgotPasswordShown = computed(() => {
  return !!route.query['forgot-password'] && !route.query.hash
})

const isUpdatePasswordByHashShown = computed(() => !!route.query.hash)
const { localizedNavigateTo } = useRouteHelpers()
const goToSignInPage = async () => await localizedNavigateTo(routeList.signin)

const { externalIDPRedirects } = await useIDPAuthentication()
const { isGreaterOrEquals } = useViewport()
</script>
