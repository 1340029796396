import { useStorage } from '@vueuse/core'

export interface LastLoggedInUser {
  firstName: string
  email: string
}

const USER_KEY = 'last_logged_in_user'

export const setUserDefault = () => {
  return {
    firstName: '',
    email: '',
  } as LastLoggedInUser
}

export const useLastLoggedInUser = async () => {
  const instance = useNuxtApp()
  const lastLoggedInUser = useState(USER_KEY, setUserDefault)
  const { user, isLoggedIn, fetching } = await useUser({ immediate: false, lazy: true })

  return instance.runWithContext(() => {
    const scope = effectScope()
    const localStorage = useStorage(USER_KEY, setUserDefault(), undefined, {
      initOnMounted: true,
      serializer: {
        read: (value: any) => (value ? JSON.parse(atob(value)) : null),
        write: (value: any) => btoa(JSON.stringify(value)),
      },
    })

    const removeLastLoggedInUser = () => {
      localStorage.value = setUserDefault()
      lastLoggedInUser.value = setUserDefault()
    }

    scope.run(() => {
      if (!import.meta.server) {
        watch(
          () => isLoggedIn.value,
          (value) => {
            if (!value) {
              return
            }

            const isGuest = user.value?.status?.isGuestCustomer

            if (isGuest) {
              return
            }

            localStorage.value = {
              firstName: user.value?.firstName,
              email: user.value?.email || '',
            } as LastLoggedInUser
          },
        )
      }
    })

    tryOnBeforeMount(() => {
      lastLoggedInUser.value = localStorage.value as LastLoggedInUser
    })

    tryOnScopeDispose(() => {
      scope.stop()
    })

    return {
      lastLoggedInUser,
      removeLastLoggedInUser,
      isLoggedIn,
      isFetching: fetching,
    }
  })
}
