<template>
  <form>
    <div class="h-16">
      <SalutationSelect v-model="editableUser.gender" />
    </div>

    <ValidatedInputGroup v-slot="{ isValid }" :errors="v.first_name.$errors">
      <FimInput
        id="first_name"
        v-model:value="editableUser.first_name"
        :debounce="0"
        autocomplete="given-name"
        :placeholder="$t('form_fields.first_name')"
        :is-valid="!isValid"
        required
        :disabled="isSubmitting"
        @update:value="v.first_name.$touch()"
      />
    </ValidatedInputGroup>

    <ValidatedInputGroup v-slot="{ isValid }" :errors="v.last_name.$errors">
      <FimInput
        id="last_name"
        v-model:value="editableUser.last_name"
        :debounce="0"
        autocomplete="family-name"
        :placeholder="$t('form_fields.last_name')"
        :is-valid="!isValid"
        required
        :disabled="isSubmitting"
        @update:value="v.last_name.$touch()"
      />
    </ValidatedInputGroup>

    <ValidatedInputGroup v-slot="{ isValid }" :errors="v.email.$errors">
      <FimInput
        id="email"
        v-model:value="editableUser.email"
        autocomplete="username"
        :placeholder="$t('form_fields.email')"
        type="email"
        :is-valid="!isValid"
        required
        :debounce="0"
        :disabled="isSubmitting"
        @update:value="v.email.$touch()"
      />
    </ValidatedInputGroup>

    <ValidatedInputGroup v-slot="{ isValid }" :errors="v.password.$errors">
      <FimInput
        id="new-password"
        v-model:value="editableUser.password"
        autocomplete="new-password"
        :placeholder="$t('form_fields.password')"
        type="password"
        :is-valid="!isValid"
        required
        :disabled="isSubmitting"
        @update:value="v.password.$touch()"
      />
    </ValidatedInputGroup>

    <FimButton
      class="mt-3 w-full"
      :disabled="isSubmitting || v.$errors.length > 0"
      :loading="isSubmitting"
      @click="onSubmit"
    >
      {{ $t('login_page.sign_up.submit') }}
    </FimButton>
  </form>
</template>

<script setup lang="ts">
import useVuelidate from '@vuelidate/core'
import type { Gender } from '@scayle/storefront-nuxt'

const { register, isSubmitting } = await useAuthentication('sign_up')
const { $validation } = useNuxtApp()

const editableUser = reactive({
  gender: 'f' as Gender,
  first_name: '',
  last_name: '',
  email: '',
  password: '',
})

const onSubmit = async () => {
  // validate all inputs
  if (await v.value.$validate()) {
    await register(editableUser)
  }
}

const v = useVuelidate(
  {
    first_name: {
      required: $validation.rule.required,
      maxLength: $validation.rule.maxLength(30),
    },
    last_name: {
      required: $validation.rule.required,
      maxLength: $validation.rule.maxLength(50),
    },
    email: {
      required: $validation.rule.required,
      email: $validation.rule.email,
    },
    password: {
      required: $validation.rule.required,
      password: $validation.rule.password,
    },
  },
  editableUser,
)
</script>
